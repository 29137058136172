<template>
  <v-sheet>
    <cit-data-table
      :appends="['invoice_link']"
      :headers="headers"
      :includes="['voucher']"
      disable-actions
      resource-path="voucher/payment-request"
    >

      <template v-slot:item.status="{item,value}">
        <inline-edit-payment-request-status :item="item" :value="value"/>
      </template>

      <template v-slot:item.invoice_link="{item}">
        <v-btn :href="item.invoice_link" color="primary" class="primary-color" icon target="_blank">
          <v-icon>fal fa-download</v-icon>
        </v-btn>
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import InlineEditPaymentRequestStatus from '@/views/Voucher/InlineEditPaymentRequestStatus'

export default {
  name: 'PaymentRequestList',
  components: {
    InlineEditPaymentRequestStatus,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Voucher',
          value: 'voucher.client_display_name',
          filterType: 'customer',
          filterName: 'voucher_id',
          sortable: false
        },
        {
          text: 'Puncte Utilizate',
          value: 'points',
          sortable: false
        },
        {
          text: 'Valoare',
          value: 'value',
          sortable: false
        },
        {
          text: 'E cash',
          value: 'is_cash',
          display: 'checkmarks',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          filterType: 'simple-select',
          filterOptions: [
            {
              text: 'Factura trimisa',
              value: 'Factura trimisa'
            },
            {
              text: 'In Asteptare',
              value: 'In Asteptare'
            },
            {
              text: 'Platit',
              value: 'Platit'
            },
            {
              text: 'Refuzat',
              value: 'Refuzat'
            },
            {
              text: 'Anulat',
              value: 'Anulat'
            }
          ],
          sortable: false
        },
        {
          text: 'Factura',
          sortable: true,
          value: 'invoice_link'
        },
        {
          text: 'Data',
          display: 'date',
          filterType: 'date-range',
          sortable: true,
          value: 'created_at'
        }
      ]
    }
  }
}
</script>
